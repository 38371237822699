import React from 'react';
import Home from './components/Home/Home';
import About from './components/About/About';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Service from './components/Services/Service';
import Portfolio from './components/Portfolio/Portfolio';
import ScrollToTop from './components/ScrollTop';
import ScrollToTopButton from './components/ScrollToTopButton';

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Service />} />
        <Route path='/portfolio' element={<Portfolio />} />
      </Routes>
      <Footer />
      {/* <ScrollToTopButton />  */}
    </BrowserRouter>
  );
}

export default App;
