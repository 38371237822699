import React, { useEffect, useState, useRef } from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import 'aos/dist/aos.css';
import Pricing from './Pricing';
import ContactSection from './Contact';
import Client from '../Client';


const Service = () => {

    

   
    useEffect(() => {
        if (window.PureCounter) {
          new window.PureCounter();
        }
      }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);


  const [refreshKey, setRefreshKey] = useState(0);

    




  return (
    <div>
        <section id="hero" className="hero section">
  <div className="container" data-aos="fade-up" data-aos-delay="100">
    <div className="row">
      <div className="col-lg-6">
        <div className="hero-content" data-aos="fade-up" data-aos-delay="200">
        <Link to="/portfolio">
          <div className="company-badge mb-4">
             
            <i className="bi bi-gear-fill me-2"></i>
            Working for your digital fitness
           
          </div>
          </Link>
          <h1 className="mb-4">
           Our Services
          </h1>
          <p className="mb-4 mb-md-5" id='para'>
          We are always striving to improve and optimize. Whether adding new features or finding ways to make your current software even better, we are here to help your business reach new heights.
          </p>
          <div className="hero-buttons">
            <Link to="/services" className="btn btn-primary me-0 me-sm-2 mx-1">Warm up for success</Link>
          </div>
        </div>
      </div>
      
      <div className="col-lg-6">
      <div className="hero-image">
          <img src="https://www.thebinarydesign.com/images/s-1-2.png" alt="Mobile" className="device mobile" />
          <img src="https://www.thebinarydesign.com/images/s-1-3.png" alt="Tablet" className="device tablet-bottom" />
          <img src="https://www.thebinarydesign.com/images/s-1-4.png" alt="Tablet" className="device tablet" />
          <img src="https://www.thebinarydesign.com/images/s-1-1.png" alt="Laptop" className="device laptop" />
      
        </div>
      </div>
    </div>
  </div>
</section>
<Client />
<section className="amazing_feature" style={{backgroundColor: "whitesmoke"}}>
  <div className="container">
    <div className="container section-title" data-aos="fade-up">
      <h2>Our Services</h2>
      <p>Here's a comprehensive list of services for Web Cloners:</p>
    </div>
    <div className="service-cards">
      
      {/* Web Development */}
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single_feature">
          <div className="feature_icon"><i className="fas fa-code" /></div>
          <h3>Web Development</h3>
          <ul>
            <li>Custom Website Design</li>
            <li>Website Development (Front-end/Back-end)</li>
            <li>Responsive Web Design</li>
            <li>E-commerce Solutions</li>
            <li>CMS Development (WordPress, Joomla, etc.)</li>
            <li>Web Portal Development</li>
          </ul>
        </div>
      </div>

      {/* Mobile App Development */}
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single_feature">
          <div className="feature_icon"><i className="fas fa-mobile-alt" /></div>
          <h3>Mobile App Development</h3>
          <ul>
            <li>iOS App Development</li>
            <li>Android App Development</li>
            <li>Cross-Platform App Development</li>
            <li>Hybrid App Development</li>
            <li>Mobile UI/UX Design</li>
            <li>App Testing and Optimization</li>
          </ul>
        </div>
      </div>

      {/* Web Applications */}
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single_feature">
          <div className="feature_icon"><i className="fas fa-globe" /></div>
          <h3>Web Applications</h3>
          <ul>
            <li>Custom Web Application Development</li>
            <li>SaaS Application Development</li>
            <li>Enterprise Web Application Development</li>
            <li>Web API Development</li>
            <li>Microservices Architecture</li>
            <li>Progressive Web Apps (PWAs)</li>
          </ul>
        </div>
      </div>

      {/* API Integration */}
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single_feature">
          <div className="feature_icon"><i className="fas fa-plug" /></div>
          <h3>API Integration</h3>
          <ul>
            <li>Third-Party API Integration</li>
            <li>Custom API Development</li>
            <li>API Testing and Optimization</li>
            <li>API Security and Authentication</li>
            <li>API Documentation</li>
          </ul>
        </div>
      </div>

      {/* Digital Solutions */}
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single_feature">
          <div className="feature_icon"><i className="fas fa-chart-line" /></div>
          <h3>Digital Solutions</h3>
          <ul>
            <li>Digital Strategy Consulting</li>
            <li>UX/UI Design</li>
            <li>Web Analytics and Optimization</li>
            <li>Digital Marketing Services (SEO, PPC, etc.)</li>
            <li>Content Management Services</li>
          </ul>
        </div>
      </div>

      {/* Maintenance and Support */}
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single_feature">
          <div className="feature_icon"><i className="fas fa-tools" /></div>
          <h3>Maintenance and Support</h3>
          <ul>
            <li>Website Maintenance</li>
            <li>App Maintenance</li>
            <li>Technical Support</li>
            <li>Bug Fixing</li>
            <li>Performance Optimization</li>
          </ul>
        </div>
      </div>

      {/* Emerging Technologies */}
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single_feature">
          <div className="feature_icon"><i className="fas fa-robot" /></div>
          <h3>Emerging Technologies</h3>
          <ul>
            <li>Artificial Intelligence (AI) Solutions</li>
            <li>Blockchain Development</li>
            <li>Internet of Things (IoT) Solutions</li>
            <li>Augmented Reality (AR) and Virtual Reality (VR)</li>
            <li>Machine Learning Solutions</li>
          </ul>
        </div>
      </div>

      {/* Other Services */}
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single_feature">
          <div className="feature_icon"><i className="fas fa-handshake" /></div>
          <h3>Other Services</h3>
          <ul>
            <li>Website Clone Services</li>
            <li>Website Migration Services</li>
            <li>Website Security Audit</li>
            <li>Performance Optimization</li>
            <li>IT Consulting Services</li>
          </ul>
        </div>
      </div>

    </div>{/*- END ROW */}     
  </div>{/*- END CONTAINER */}   
</section>



<section id="stats" className="stats section">
  <div className="container" data-aos="fade-up" data-aos-delay={100}>
    <div className="row gy-4">
      <div className="col-lg-3 col-md-6">
        <div className="stats-item text-center w-100 h-100">
          <span data-purecounter-start={0} data-purecounter-end={232} data-purecounter-duration={1} className="purecounter" />
          <p>Clients</p>
        </div>
      </div>{/* End Stats Item */}
      <div className="col-lg-3 col-md-6">
        <div className="stats-item text-center w-100 h-100">
          <span data-purecounter-start={0} data-purecounter-end={521} data-purecounter-duration={1} className="purecounter" />
          <p>Projects</p>
        </div>
      </div>{/* End Stats Item */}
      <div className="col-lg-3 col-md-6">
        <div className="stats-item text-center w-100 h-100">
          <span data-purecounter-start={0} data-purecounter-end={1453} data-purecounter-duration={1} className="purecounter" />
          <p>Hours Of Support</p>
        </div>
      </div>{/* End Stats Item */}
      <div className="col-lg-3 col-md-6">
        <div className="stats-item text-center w-100 h-100">
          <span data-purecounter-start={0} data-purecounter-end={32} data-purecounter-duration={1} className="purecounter" />
          <p>Workers</p>
        </div>
      </div>{/* End Stats Item */}
    </div>
  </div>
</section>

<section id="banner" className="call-to-action section">
  <div className="container" data-aos="fade-up" data-aos-delay={100}>
    <div className="row justify-content-center align-items-center position-relative">
      <div className="col-lg-8 mx-auto text-center">
        <h2 className="display-4 mb-4">Maecenas tempus tellus eget condimentum</h2>
        <p className="mb-4">
          Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel
        </p>
      </div>
      
      {/* Abstract Background Elements */}
      <div className="shape shape-1">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M47.1,-57.1C59.9,-45.6,68.5,-28.9,71.4,-10.9C74.2,7.1,71.3,26.3,61.5,41.1C51.7,55.9,35,66.2,16.9,69.2C-1.3,72.2,-21,67.8,-36.9,57.9C-52.8,48,-64.9,32.6,-69.1,15.1C-73.3,-2.4,-69.5,-22,-59.4,-37.1C-49.3,-52.2,-32.8,-62.9,-15.7,-64.9C1.5,-67,34.3,-68.5,47.1,-57.1Z" transform="translate(100 100)" />
        </svg>
      </div>
      <div className="shape shape-2">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M41.3,-49.1C54.4,-39.3,66.6,-27.2,71.1,-12.1C75.6,3,72.4,20.9,63.3,34.4C54.2,47.9,39.2,56.9,23.2,62.3C7.1,67.7,-10,69.4,-24.8,64.1C-39.7,58.8,-52.3,46.5,-60.1,31.5C-67.9,16.4,-70.9,-1.4,-66.3,-16.6C-61.8,-31.8,-49.7,-44.3,-36.3,-54C-22.9,-63.7,-8.2,-70.6,3.6,-75.1C15.4,-79.6,28.2,-58.9,41.3,-49.1Z" transform="translate(100 100)" />
        </svg>
      </div>

      {/* Dot Pattern Groups */}
      <div className="dots dots-1">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <pattern id="dot-pattern" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
            <circle cx={2} cy={2} r={2} fill="currentColor" />
          </pattern>
          <rect width={100} height={100} fill="url(#dot-pattern)" />
        </svg>
      </div>
    </div>
  </div>
</section>

<Pricing />




{/* <ContactSection /> */}



    </div>
  )
}

export default Service;