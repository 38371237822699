import React, { useEffect, useState, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import Testimonials from './Testimonials'
import Popup from './popup';
import Newsletter from './NewsLetter';
import Client from '../Client';

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const { ref: aboutRef, inView: aboutInView } = useInView({
    triggerOnce: true, // Only trigger once when it enters the viewport
    threshold: 0.5, // Trigger when 10% of the section is in view
  });
  const { ref: featuresRef, inView: featuresInView } = useInView({
    triggerOnce: true, // Only trigger once when it enters the viewport
    threshold: 0.5, // Trigger when 10% of the section is in view
  });
  const [features2InView, setFeatures2InView] = useState(false);
  const features2Ref = useRef(null);
  

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setFeatures2InView(true);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (features2Ref.current) {
      observer.observe(features2Ref.current);
    }

    return () => {
      if (features2Ref.current) {
        observer.unobserve(features2Ref.current);
      }
    };
  }, []);


  // useEffect(() => {
  //   // Show the popup after 3 seconds
  //   const timer = setTimeout(() => {
  //     setShowPopup(true);
  //   }, 3000);

    // Clear the timer on component unmount
  //   return () => clearTimeout(timer);
  // }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  

  const faqData = [
    { question: "What is your development process?", answer: "Our development process follows an Agile methodology, involving requirements gathering, design, development, testing, and deployment. We emphasize collaboration, flexibility, and continuous improvement." },
    { question: "How long does a typical project take?", answer: "Project timelines vary depending on scope, complexity, and technology. We provide customized timelines and milestones for each project, ensuring transparency and effective project management." },
    { question: "Do you provide post-launch support and maintenance?", answer: "Yes, we offer comprehensive support and maintenance services, including bug fixing, updates, and performance optimization. Our goal is to ensure your solution continues to meet evolving business needs." },
    { question: "How do you ensure SEO best practices in web development?" , answer: "Our development team incorporates SEO-friendly design and coding practices, including optimized HTML, meta tags, and structured data. We also collaborate with SEO experts to ensure search engine rankings and visibility." },
    { question: "What security measures do you implement for API integration?", answer: "We prioritize API security through robust authentication, authorization, encryption, and secure data storage. Our experts stay up-to-date with industry standards and best practices to safeguard sensitive data." },
    { question: "Can you integrate third-party services into my mobile app or website?", answer: "Yes, our team has extensive experience integrating third-party APIs, services, and platforms (e.g., social media, payment gateways, mapping). We ensure seamless integration and optimal functionality." },
  ];

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowPopup(true), 2000); // 5 seconds delay
    return () => clearTimeout(timer);
  }, []);

  const [email, setEmail] = useState('');
 

    // Check if the email contains "@" and ends with "@gmail.com"
 
    // Clear the error and show success message
   


  return (
    <div>
      {showPopup && <Popup onClose={() => setShowPopup(false)} />}


    <main class="main">
   <section id="hero" className="hero section">
  <div className="container" data-aos="fade-up" data-aos-delay="100">
    <div className="row">
      <div className="col-lg-6">
        <div className="hero-content" data-aos="fade-up" data-aos-delay="200">
          <Link to="/portfolio">
          <div className="company-badge mb-4">
             
            <i className="bi bi-gear-fill me-2"></i>
            Working for your digital fitness
           
          </div>
          </Link>
          <h1 className="mb-4">
          Supplement Your <br />
          Online Presence with <br />
            <span className="accent-text"> Our Expert Services,</span>
          </h1>
          <p className="mb-4 mb-md-5" id='para'>
          Complete your digital fitness routine with expert solutions. Webcloners - building stronger online presence
          </p>
          <div className="hero-buttons">
            <Link to="/services" className="btn btn-primary me-0 me-sm-2 mx-1">Warm up for success</Link>
          </div>
        </div>
      </div>
      
      <div className="col-lg-6">
      <div className="hero-image">
          <img src="assets/img/hero/mobile.png" alt="Mobile" className="device mobile" />
          <img src="assets/img/hero/tablet1.png" alt="Tablet" className="device tablet-bottom" />
          <img src="assets/img/hero/tablet2.png" alt="Tablet" className="device tablet" />
          <img src="assets/img/hero/laptop.png" alt="Laptop" className="device laptop" />
      
        </div>
      </div>
    </div>
    <div className="row stats-row gy-4 mt-5" data-aos="fade-up" data-aos-delay="500">
  <div className="col-lg-3 col-md-6">
    <div className="stat-item">
      <div className="stat-icon">
        <i className="bi bi-briefcase"></i> {/* Proven Digital Results */}
      </div>
      <div className="stat-content">
        <h4>Proven Digital Results</h4>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="stat-item">
      <div className="stat-icon">
        <i className="bi bi-heart"></i> {/* Client Love Rate */}
      </div>
      <div className="stat-content">
        <h4>Client Love Rate</h4>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="stat-item">
      <div className="stat-icon">
        <i className="bi bi-graph-up"></i> {/* Traffic Surge Expert */}
      </div>
      <div className="stat-content">
        <h4>Traffic Surge Expert</h4>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="stat-item">
      <div className="stat-icon">
        <i className="bi bi-alarm"></i> {/* Timely Delivery Guaranteed */}
      </div>
      <div className="stat-content">
        <h4>Timely Delivery Guaranteed</h4>
      </div>
    </div>
  </div>
</div>


  </div>
</section>

<Client />
   
<section id="about" style={{backgroundColor: '#F9F9F9'}} className="about section" ref={aboutRef}>
      <div className="container" data-aos="fade-up" data-aos-delay={100}>
        <div className="row gy-4 align-items-center justify-content-between">
          <div className="col-xl-5" data-aos="fade-up" data-aos-delay={200}>
            <span className={`about-meta ${aboutInView ? 'in-view' : ''}`}>Our Key Strengths</span>
            <h2 className={`about-title ${aboutInView ? 'in-view' : ''}`}>
              Delivering Exceptional Digital Solutions: Our Core Competencies.
            </h2>
            <p className={`about-description ${aboutInView ? 'in-view' : ''}`}>
              At Webcloners, we pride ourselves on delivering exceptional digital solutions that drive real results. Here's what sets us apart:
            </p>
            <div className="row feature-list-wrapper">
              <div className="col-md-6">
                <ul className="feature-list">
                  {['Proven Track Record of Success', 'Expert Team with 10+ Years Experience', 'Personalized Solutions for Each Client', 'Agile Development Methodology'].map((item, index) => (
                    <li key={index} className={aboutInView ? 'in-view' : ''}>
                      <i className="bi bi-check-circle-fill" /> {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="feature-list">
                  {['Cutting-Edge Technology Stack', 'Fast Turnaround Times', 'Scalable and Secure Infrastructure', 'Continuous Innovation and Improvement'].map((item, index) => (
                    <li key={index} className={aboutInView ? 'in-view' : ''}>
                      <i className="bi bi-check-circle-fill" /> {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="info-wrapper">
              <div className="row gy-4">
                <div className="col-lg-5">
                  <div className="profile d-flex align-items-center gap-3">
                    <img src="assets/img/avatar-1.webp" alt="CEO Profile" className="profile-image" />
                    <div>
                      <h4 className={`profile-name ${aboutInView ? 'in-view' : ''}`}>Mario Smith</h4>
                      <p className={`profile-position ${aboutInView ? 'in-view' : ''}`}>CEO &amp; Founder</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
      <div className={`contact-info d-flex align-items-center gap-2 ${aboutInView ? 'in-view' : ''}`}>
        <i className={`bi bi-telephone-fill ${aboutInView ? 'in-view' : ''}`} />
        <div>
          <p className={`contact-label ${aboutInView ? 'in-view' : ''}`}>Call us anytime</p>
          <a href="tel:+17373590066"  className={`contact-number ${aboutInView ? 'in-view' : ''}`} style={{color: "#211252"}}  >
            +1 (737) 359 0066
          </a>
        </div>
      </div>
    </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6" data-aos="fade-up" data-aos-delay={300}>
            <div className="image-wrapper">
              <div className="images position-relative" data-aos="zoom-out" data-aos-delay={400}>
                <img src="/assets/img/about-1.jpg" alt="Business Meeting" className="img-fluid main-image rounded-4" />
                <img src="assets/img/about-2.webp" alt="Team Discussion" className="img-fluid small-image rounded-4" />
              </div>
              <div className="experience-badge floating">
                <h3>15+ <span>Years</span></h3>
                <p>Of experience in Development services.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="features" className="features section" ref={featuresRef}>
  {/* Section Title */}
  <div className="container section-title" data-aos="fade-up">
    <h2 className={`section-title ${featuresInView ? 'in-view' : ''}`}>What We Offer</h2>
    <p className={`section-title ${featuresInView ? 'in-view' : ''}`}>
      Programming Supplements to Boost Performance and Efficiency
    </p>
  </div>{/* End Section Title */}
  
  <div className="container">
    <div className="d-flex justify-content-center">
      <ul className="nav nav-tabs" data-aos="fade-up" data-aos-delay={100}>
        <li className="nav-item">
          <a className={`nav-link active show ${featuresInView ? 'in-view' : ''}`} data-bs-toggle="tab" data-bs-target="#features-tab-1">
            <h4>Web & Mobile App Development</h4>
          </a>
        </li>{/* End tab nav item */}
        <li className="nav-item">
          <a className={`nav-link ${featuresInView ? 'in-view' : ''}`} data-bs-toggle="tab" data-bs-target="#features-tab-2">
            <h4>API & Integration</h4>
          </a>{/* End tab nav item */}
        </li>
        <li className="nav-item">
          <a className={`nav-link ${featuresInView ? 'in-view' : ''}`} data-bs-toggle="tab" data-bs-target="#features-tab-3">
            <h4>SEO</h4>
          </a>
        </li>{/* End tab nav item */}
      </ul>
    </div>
    
    <div className="tab-content" data-aos="fade-up" data-aos-delay={200}>
      <div className={`tab-pane fade show active ${featuresInView ? 'in-view' : ''}`} id="features-tab-1">
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
            <h3 className={`${featuresInView ? 'in-view' : ''}`}>Web & Mobile App Development</h3>
            <p className={`fst-italic ${featuresInView ? 'in-view' : ''}`}>
              Build. Launch. Grow.
              Transform your business with our cutting-edge web and mobile app development solutions.
            </p>
            <ul>
              <li><i className="bi bi-check2-all" /> <span>Expertise in Latest Tech: Our team of experts leverages the latest technologies (React, Angular, Vue.js, Flutter, etc.) to craft scalable, secure, and user-friendly applications.</span></li>
              <li><i className="bi bi-check2-all" /> <span>Agile Development Methodology: We follow an iterative approach, ensuring rapid deployment, flexible changes, and continuous improvement.</span></li>
            </ul>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 text-center">
            <img src="assets/img/feautures/WebAndApplication.jpg" alt className="img-fluid" />
          </div>
        </div>
      </div>{/* End tab content item */}
      
      <div className={`tab-pane fade ${featuresInView ? 'in-view' : ''}`} id="features-tab-2">
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
            <h3 className={`${featuresInView ? 'in-view' : ''}`}>API Creation & Integration</h3>
            <p className={`fst-italic ${featuresInView ? 'in-view' : ''}`}>
              Connect. Automate. Innovate.
              Streamline your operations and enhance user experience with our custom API creation and integration services.
            </p>
            <ul>
              <li><i className="bi bi-check2-all" /> <span>Technical Expertise: Our team has extensive experience in designing, developing, and integrating APIs (RESTful, GraphQL, SOAP, etc.) for seamless data exchange.</span></li>
              <li><i className="bi bi-check2-all" /> <span>Secure and Scalable Solutions: We ensure robust security measures and scalable architecture to handle high traffic and complex data transactions.</span></li>
            </ul>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 text-center">
            <img src="assets/img/feautures/Api.jpg" alt className="img-fluid" />
          </div>
        </div>
      </div>{/* End tab content item */}

      
      
      <div className={`tab-pane fade ${featuresInView ? 'in-view' : ''}`} id="features-tab-3">
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
            <h3 className={`${featuresInView ? 'in-view' : ''}`}>SEO (Search Engine Optimization)</h3>
            <p className={`fst-italic ${featuresInView ? 'in-view' : ''}`}>
              Rank Higher. Reach Further.
              Boost your online visibility and drive organic traffic with our proactive SEO strategies.
            </p>
            <ul>
              <li><i className="bi bi-check2-all" /> <span>Proven Track Record: Our SEO experts have consistently delivered top-ranking results for clients, increasing website traffic and conversions.</span></li>
              <li><i className="bi bi-check2-all" /> <span>Personalized Strategies: We tailor our SEO approach to your unique business needs, targeting relevant keywords and audiences.</span></li>
            </ul>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 text-center">
            <img src="assets/img/feautures/SEO.jpg" alt className="img-fluid" />
          </div>
        </div>
      </div>{/* End tab content item */}
    </div>
  </div>
</section>



<section id="features-2" style={{backgroundColor: '#F9F9F9'}} className="features-2 section" ref={features2Ref}>
      <div className="container" data-aos="fade-up" data-aos-delay={100}>
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className={`feature-item text-end mb-5 ${features2InView ? 'in-view' : ''}`} data-aos="fade-right" data-aos-delay={200}>
              <div className="d-flex align-items-center justify-content-end gap-4">
                <div className="feature-content">
                  <h3>Customized User Experience</h3>
                  <p>Designing intuitive and engaging interfaces tailored to your brand's unique identity, ensuring seamless user interactions and maximum engagement.</p>
                </div>
                <div className="feature-icon flex-shrink-0">
                  <i className="bi bi-display" />
                </div>
              </div>
            </div>
            <div className={`feature-item text-end mb-5 ${features2InView ? 'in-view' : ''}`} data-aos="fade-right" data-aos-delay={300}>
              <div className="d-flex align-items-center justify-content-end gap-4">
                <div className="feature-content">
                  <h3>Cross-Platform Development</h3>
                  <p>Building apps that run smoothly on multiple platforms (iOS, Android, React Native, Flutter), maximizing reach and minimizing development costs.</p>
                </div>
                <div className="feature-icon flex-shrink-0">
                  <i className="bi bi-feather" />
                </div>
              </div>
            </div>
            <div className={`feature-item text-end ${features2InView ? 'in-view' : ''}`} data-aos="fade-right" data-aos-delay={400}>
              <div className="d-flex align-items-center justify-content-end gap-4">
                <div className="feature-content">
                  <h3>Secure Data Integration</h3>
                  <p>Implementing robust security measures to protect sensitive user data, ensuring secure API integrations, and complying with industry standards (GDPR, HIPAA).</p>
                </div>
                <div className="feature-icon flex-shrink-0">
                  <i className="bi bi-eye" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4" data-aos="zoom-in" data-aos-delay={200}>
            <div className="phone-mockup text-center">
            <img src="assets/img/phone-app-screen.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className={`feature-item mb-5 ${features2InView ? 'in-view' : ''}`} data-aos="fade-left" data-aos-delay={200}>
              <div className="d-flex align-items-center gap-4">
                <div className="feature-icon flex-shrink-0">
                  <i className="bi bi-code-square" />
                </div>
                <div className="feature-content">
                  <h3>Cloud-Based Infrastructure</h3>
                  <p>Leveraging scalable cloud services (AWS, Google Cloud, Azure) for reliable data storage, efficient content delivery, and automatic software updates.</p>
                </div>
              </div>
            </div>
            <div className={`feature-item mb-5 ${features2InView ? 'in-view' : ''}`} data-aos="fade-left" data-aos-delay={300}>
              <div className="d-flex align-items-center gap-4">
                <div className="feature-icon flex-shrink-0">
                  <i className="bi bi-phone" />
                </div>
                <div className="feature-content">
                  <h3>Agile Development Methodology</h3>
                  <p>Employing iterative development processes to ensure rapid prototyping, flexible changes, and continuous testing, resulting in faster time-to-market.</p>
                </div>
              </div>
            </div>
            <div className={`feature-item ${features2InView ? 'in-view' : ''}`} data-aos="fade-left" data-aos-delay={400}>
              <div className="d-flex align-items-center gap-4">
                <div className="feature-icon flex-shrink-0">
                  <i className="bi bi-browser-chrome" />
                </div>
                <div className="feature-content">
                  <h3>Performance Optimization</h3>
                  <p>Optimizing app performance through efficient coding, caching, and content compression, ensuring:
                    <ul>
                      <li>Fast load times</li>
                      <li>Low latency</li>
                      <li>Smooth animations</li>
                      <li>Extended battery life</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



<section id="call-to-action" className="call-to-action section">
  <div className="container" data-aos="fade-up" data-aos-delay={100}>
    <div className="row justify-content-center align-items-center position-relative">
      <div className="col-lg-8 mx-auto text-center">
        <h2 className="display-4 mb-4">Run Faster, Grow Stronger with Mobile Apps</h2>
        <p className="mb-4">
        Transform your business with mobile apps, accelerating growth, revenue and customer acquisition through cutting-edge technology.
        </p>
      </div>
      
      {/* Abstract Background Elements */}
      <div className="shape shape-1">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M47.1,-57.1C59.9,-45.6,68.5,-28.9,71.4,-10.9C74.2,7.1,71.3,26.3,61.5,41.1C51.7,55.9,35,66.2,16.9,69.2C-1.3,72.2,-21,67.8,-36.9,57.9C-52.8,48,-64.9,32.6,-69.1,15.1C-73.3,-2.4,-69.5,-22,-59.4,-37.1C-49.3,-52.2,-32.8,-62.9,-15.7,-64.9C1.5,-67,34.3,-68.5,47.1,-57.1Z" transform="translate(100 100)" />
        </svg>
      </div>
      <div className="shape shape-2">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M41.3,-49.1C54.4,-39.3,66.6,-27.2,71.1,-12.1C75.6,3,72.4,20.9,63.3,34.4C54.2,47.9,39.2,56.9,23.2,62.3C7.1,67.7,-10,69.4,-24.8,64.1C-39.7,58.8,-52.3,46.5,-60.1,31.5C-67.9,16.4,-70.9,-1.4,-66.3,-16.6C-61.8,-31.8,-49.7,-44.3,-36.3,-54C-22.9,-63.7,-8.2,-70.6,3.6,-75.1C15.4,-79.6,28.2,-58.9,41.3,-49.1Z" transform="translate(100 100)" />
        </svg>
      </div>

      {/* Dot Pattern Groups */}
      <div className="dots dots-1">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <pattern id="dot-pattern" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
            <circle cx={2} cy={2} r={2} fill="currentColor" />
          </pattern>
          <rect width={100} height={100} fill="url(#dot-pattern)" />
        </svg>
      </div>
    </div>
  </div>
</section>



<Testimonials />
<section id="banner" className="call-to-action section">
  <div className="container" data-aos="fade-up" data-aos-delay={100}>
    <div className="row justify-content-center align-items-center position-relative">
      <div className="col-lg-8 mx-auto text-center">
        <h2 className="display-4 mb-4">Testimonials That Inspire
        </h2>
        <p className="mb-4">
        Growing stronger together! Thank you for your valued testimonials.
        </p>
      </div>
      
      {/* Abstract Background Elements */}
      <div className="shape shape-1">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M47.1,-57.1C59.9,-45.6,68.5,-28.9,71.4,-10.9C74.2,7.1,71.3,26.3,61.5,41.1C51.7,55.9,35,66.2,16.9,69.2C-1.3,72.2,-21,67.8,-36.9,57.9C-52.8,48,-64.9,32.6,-69.1,15.1C-73.3,-2.4,-69.5,-22,-59.4,-37.1C-49.3,-52.2,-32.8,-62.9,-15.7,-64.9C1.5,-67,34.3,-68.5,47.1,-57.1Z" transform="translate(100 100)" />
        </svg>
      </div>
      <div className="shape shape-2">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M41.3,-49.1C54.4,-39.3,66.6,-27.2,71.1,-12.1C75.6,3,72.4,20.9,63.3,34.4C54.2,47.9,39.2,56.9,23.2,62.3C7.1,67.7,-10,69.4,-24.8,64.1C-39.7,58.8,-52.3,46.5,-60.1,31.5C-67.9,16.4,-70.9,-1.4,-66.3,-16.6C-61.8,-31.8,-49.7,-44.3,-36.3,-54C-22.9,-63.7,-8.2,-70.6,3.6,-75.1C15.4,-79.6,28.2,-58.9,41.3,-49.1Z" transform="translate(100 100)" />
        </svg>
      </div>

      {/* Dot Pattern Groups */}
      <div className="dots dots-1">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <pattern id="dot-pattern" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
            <circle cx={2} cy={2} r={2} fill="currentColor" />
          </pattern>
          <rect width={100} height={100} fill="url(#dot-pattern)" />
        </svg>
      </div>
    </div>
  </div>
</section>




    <section className="faq-9 faq section light-background" style={{backgroundColor: '#F9F9F9'}} id="faq">
      <div className="container">
        <div className="row">
          <div className="col-lg-5" data-aos="fade-up">
            <h2 className="faq-title">FAQs: Your Questions, Answered</h2>
            <p className="faq-description">Find clarity on our development process, timelines, support, and security measures. Our FAQs provide insights into our approach, ensuring transparency and confidence in our services.</p>
            <div class="faq-arrow d-none d-lg-block" data-aos="fade-up" data-aos-delay="200">
              <svg class="faq-arrow" width="200" height="211" viewBox="0 0 200 211" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M198.804 194.488C189.279 189.596 179.529 185.52 169.407 182.07L169.384 182.049C169.227 181.994 169.07 181.939 168.912 181.884C166.669 181.139 165.906 184.546 167.669 185.615C174.053 189.473 182.761 191.837 189.146 195.695C156.603 195.912 119.781 196.591 91.266 179.049C62.5221 161.368 48.1094 130.695 56.934 98.891C84.5539 98.7247 112.556 84.0176 129.508 62.667C136.396 53.9724 146.193 35.1448 129.773 30.2717C114.292 25.6624 93.7109 41.8875 83.1971 51.3147C70.1109 63.039 59.63 78.433 54.2039 95.0087C52.1221 94.9842 50.0776 94.8683 48.0703 94.6608C30.1803 92.8027 11.2197 83.6338 5.44902 65.1074C-1.88449 41.5699 14.4994 19.0183 27.9202 1.56641C28.6411 0.625793 27.2862 -0.561638 26.5419 0.358501C13.4588 16.4098 -0.221091 34.5242 0.896608 56.5659C1.8218 74.6941 14.221 87.9401 30.4121 94.2058C37.7076 97.0203 45.3454 98.5003 53.0334 98.8449C47.8679 117.532 49.2961 137.487 60.7729 155.283C87.7615 197.081 139.616 201.147 184.786 201.155L174.332 206.827C172.119 208.033 174.345 211.287 176.537 210.105C182.06 207.125 187.582 204.122 193.084 201.144C193.346 201.147 195.161 199.887 195.423 199.868C197.08 198.548 193.084 201.144 195.528 199.81C196.688 199.192 197.846 198.552 199.006 197.935C200.397 197.167 200.007 195.087 198.804 194.488ZM60.8213 88.0427C67.6894 72.648 78.8538 59.1566 92.1207 49.0388C98.8475 43.9065 106.334 39.2953 114.188 36.1439C117.295 34.8947 120.798 33.6609 124.168 33.635C134.365 33.5511 136.354 42.9911 132.638 51.031C120.47 77.4222 86.8639 93.9837 58.0983 94.9666C58.8971 92.6666 59.783 90.3603 60.8213 88.0427Z" fill="currentColor"></path>
              </svg>
            </div>
          </div>
          <div className="col-lg-7" data-aos="fade-up" data-aos-delay={300}>
            <div className="faq-container">
              {faqData.map((item, index) => (
                <div
                  className={`faq-item ${activeIndex === index ? "faq-active" : ""}`}
                  key={index}
                  onClick={() => toggleFAQ(index)}
                >
                  <h3>{item.question}</h3>
                  <div className="faq-content">
                    <p>{item.answer}</p>
                  </div>
                  <i className={`faq-toggle bi bi-chevron-right ${activeIndex === index ? "rotate" : ""}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>

    

   <Newsletter />

    </main>
    </div>
  );
}

export default Home;
