import React, { useState } from "react";
import Swal from "sweetalert2";

const ContactSection = ({ selectedPackage }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Mocking a successful form submission
    try {
      // Here, you would typically send `formData` to your server using Axios or Fetch API.
      // Example:
      // await axios.post("forms/contact.php", formData);

      // Show success message
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Message Sent!",
        text: "Thank you for reaching out to us. We'll get back to you shortly.",
        showConfirmButton: false,
        timer: 4000,
        toast: true,
      });

      // Reset the form fields
      setFormData({
        name: "",
        email: "",
        number: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      // Handle errors here if the request fails
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Submission Failed",
        text: "Something went wrong. Please try again.",
        showConfirmButton: false,
        timer: 4000,
        toast: true,
      });
    }
  };

  return (
    <section id="contact" className="contact section light-background">
      <div className="container section-title" data-aos="fade-up">
        <h2>Connect with Us</h2>
        <p>Let's build something amazing together.</p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row g-4 g-lg-5">
          <div className="col-lg-5">
            <div className="info-box" data-aos="fade-up" data-aos-delay={200}>
              <h3>From Concept to Reality: Web Cloners' End-to-End Solutions</h3>
              <p>
                At Web Cloners, we turn visionary ideas into captivating reality.
                Our expert team crafts tailored web and mobile solutions, blending
                innovation, design, and technical mastery. With precision and passion.
              </p>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="contact-form" data-aos="fade-up" data-aos-delay={300}>
              <h3>We respect your privacy. Data confidentiality ensured.</h3>
              <form
                onSubmit={handleSubmit}
                className="php-email-form"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Full Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email Address"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="number"
                      className="form-control"
                      name="number"
                      placeholder="Your Contact Number"
                      value={formData.number}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  {selectedPackage && (
                    <>
                      <div className="col-6">
                        <input
                          type="text"
                          className="form-control"
                          name="package"
                          value={selectedPackage.title}
                          disabled
                          required
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="number"
                          className="form-control"
                          name="price"
                          value={selectedPackage.price}
                          disabled
                          required
                        />
                      </div>
                    </>
                  )}

                  <div className="col-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="6"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
