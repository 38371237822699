import React from 'react'

const Client = () => {
  return (
    <section id="clients"  class="clients section">
  <div className="slider">
    <div className="slide-track swiper-wrapper align-items-center">
      <div className="slide">
        <img className="slider-image swiper-slide" src="assets/img/clients/client-1.png" alt />
      </div>
      <div className="slide">
        <img className="slider-image swiper-slide" src="assets/img/clients/client-2.png" alt />
      </div>
      <div className="slide">
        <img className="slider-image swiper-slide" src="assets/img/clients/client-3.png" alt />
      </div>
      <div className="slide">
        <img className="slider-image swiper-slide" src="assets/img/clients/client-4.png" alt />
      </div>
      <div className="slide">
        <img className="slider-image swiper-slide" style={{width: `80%`, paddingLeft: "0px"}} src="assets/img/clients/client-5.png" alt />
      </div>
      <div className="slide">
        <img className="slider-image swiper-slide" src="assets/img/clients/client-6.png" alt />
      </div>
      <div className="slide">
        <img className="slider-image swiper-slide" src="assets/img/clients/client-7.png" alt />
      </div>
      <div className="slide">
        <img className="slider-image swiper-slide" src="assets/img/clients/client-8.jpg" alt />
      </div>
      <div className="slide">
            <img className="slider-image" src="assets/img/clients/client-1.png" alt="Client 1" />
          </div>
          <div className="slide">
            <img className="slider-image" src="assets/img/clients/client-2.png" alt="Client 2" />
          </div>
          <div className="slide">
        <img className="slider-image swiper-slide" src="assets/img/clients/client-6.png" alt />
      </div>
      <div className="slide">
        <img className="slider-image swiper-slide" src="assets/img/clients/client-7.png" alt />
      </div>
      <div className="slide">
        <img className="slider-image swiper-slide" src="assets/img/clients/client-8.jpg" alt />
      </div>
    </div>
  </div>
</section>
  )
}

export default Client