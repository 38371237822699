import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import 'aos/dist/aos.css';
import Client from '../Client';

const About = () => {

    useEffect(() => {
        if (window.PureCounter) {
          new window.PureCounter();
        }
      }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  

  const faqData = [
    { question: "What is your development process?", answer: "Our development process follows an Agile methodology, involving requirements gathering, design, development, testing, and deployment. We emphasize collaboration, flexibility, and continuous improvement." },
    { question: "How long does a typical project take?", answer: "Project timelines vary depending on scope, complexity, and technology. We provide customized timelines and milestones for each project, ensuring transparency and effective project management." },
    { question: "Do you provide post-launch support and maintenance?", answer: "Yes, we offer comprehensive support and maintenance services, including bug fixing, updates, and performance optimization. Our goal is to ensure your solution continues to meet evolving business needs." },
    { question: "How do you ensure SEO best practices in web development?" , answer: "Our development team incorporates SEO-friendly design and coding practices, including optimized HTML, meta tags, and structured data. We also collaborate with SEO experts to ensure search engine rankings and visibility." },
    { question: "What security measures do you implement for API integration?", answer: "We prioritize API security through robust authentication, authorization, encryption, and secure data storage. Our experts stay up-to-date with industry standards and best practices to safeguard sensitive data." },
    { question: "Can you integrate third-party services into my mobile app or website?", answer: "Yes, our team has extensive experience integrating third-party APIs, services, and platforms (e.g., social media, payment gateways, mapping). We ensure seamless integration and optimal functionality." },
  ];

  const aboutData = [
    { question: "Why choose Web Cloners despite higher costs?", answer: "While we may not be the cheapest option, our premium quality, expertise and dedication ensure long-term savings through efficient solutions, reduced maintenance and increased revenue growth." },
    { question: "What sets Web Cloners apart from competitors?", answer: "Our unique blend of innovative thinking, technical mastery and passion for excellence delivers unparalleled results. Our team's expertise, creativity and commitment ensure tailored solutions that exceed expectations." },
    { question: "Why wait for Web Cloners when others promise faster turnaround?", answer: "Rushing projects compromises quality. We prioritize precision, taking the time necessary to craft exceptional solutions that drive lasting impact. Your patience yields superior results." },
    { question: "How does Web Cloners handle busy schedules and multiple projects?", answer: "Our agile methodology and experienced team manage multiple projects efficiently, ensuring focused attention and timely delivery. Your project receives dedicated care." },
    { question: "What assurance do I have of Web Cloners' commitment to my success?", answer: "Our success relies on yours! We foster collaborative partnerships, listening attentively to understand your vision. Our invested team delivers personalized solutions to propel your growth." },
    { question: "Why trust Web Cloners for complex, specialized projects?", answer: "Our expertise spans web and mobile app development, API integration and more. Trust our specialized knowledge, innovative approach and passion for solving complex challenges to drive your business forward." },
  ];

  return (
    <div>
    
    <main class="main">

        
   <section id="hero" className="hero section">
  <div className="container" data-aos="fade-up" data-aos-delay="100">
    <div className="row">
      <div className="col-lg-6">
        <div className="hero-content" data-aos="fade-up" data-aos-delay="200">
        <Link to="/portfolio">
          <div className="company-badge mb-4">
             
            <i className="bi bi-gear-fill me-2"></i>
            Working for your digital fitness
           
          </div>
          </Link>
          <h1 className="mb-4">
           About 
            Us
          </h1>
          <p className="mb-4 mb-md-5" id='para'>
          Welcome to Web Cloners, your fitness coaches for digital success! We're a team of tech athletes passionate about empowering businesses to reach new heights
          </p>
          <div className="hero-buttons">
            <Link to="/services" className="btn btn-primary me-0 me-sm-2 mx-1">Warm up for success</Link>
          </div>
        </div>
      </div>
      
      <div className="col-lg-6">
      <div className="hero-image">
          <img src="https://www.thebinarydesign.com/images/s-1-2.png" alt="Mobile" className="device mobile" />
          <img src="https://www.thebinarydesign.com/images/s-1-3.png" alt="Tablet" className="device tablet-bottom" />
          <img src="https://www.thebinarydesign.com/images/s-1-4.png" alt="Tablet" className="device tablet" />
          <img src="https://www.thebinarydesign.com/images/s-1-1.png" alt="Laptop" className="device laptop" />
      
        </div>
      </div>
    </div>
  </div>
</section>

<Client />

<section className="faq-9 faq section light-background" style={{ backgroundColor: "whitesmoke" }} id="faq">
  <div className="container">
  <h2 className="faq-title" style={{textAlign: 'center'}}>Why Choose Web Cloners?
  </h2>
        <p style={{textAlign: 'center'}} className="faq-description">
        Experience the Web Cloners difference. Exceptional solutions, unmatched expertise.
        </p>
    <div className="row">
      <div className="col-lg-5" data-aos="fade-up">
       
        <div data-aos="fade-up" data-aos-delay="200">
          <img
            className="img-fluid"
            style={{ maxWidth: '100%', height: 'auto', paddingBottom: '20px' }} // Ensure it doesn’t exceed container width
            src="assets/img/About-img1.png"
            alt=""
          />
        </div>
      </div>
      <div className="col-lg-7" data-aos="fade-up" data-aos-delay={300}>
        <div className="faq-container">
          {aboutData.map((item, index) => (
            <div
              className={`faq-item ${activeIndex === index ? "faq-active" : ""}`}
              key={index}
              onClick={() => toggleFAQ(index)}
            >
              <h3>{item.question}</h3>
              <div className="faq-content">
                <p>{item.answer}</p>
              </div>
              <i className={`faq-toggle bi bi-chevron-right ${activeIndex === index ? "rotate" : ""}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
</section>


<section id="stats" className="stats section">
  <div className="container" data-aos="fade-up" data-aos-delay={100}>
    <div className="row gy-4">
      <div className="col-lg-3 col-md-6">
        <div className="stats-item text-center w-100 h-100">
          <span data-purecounter-start={0} data-purecounter-end={232} data-purecounter-duration={1} className="purecounter" />
          <p>Clients</p>
        </div>
      </div>{/* End Stats Item */}
      <div className="col-lg-3 col-md-6">
        <div className="stats-item text-center w-100 h-100">
          <span data-purecounter-start={0} data-purecounter-end={521} data-purecounter-duration={1} className="purecounter" />
          <p>Projects</p>
        </div>
      </div>{/* End Stats Item */}
      <div className="col-lg-3 col-md-6">
        <div className="stats-item text-center w-100 h-100">
          <span data-purecounter-start={0} data-purecounter-end={1453} data-purecounter-duration={1} className="purecounter" />
          <p>Hours Of Support</p>
        </div>
      </div>{/* End Stats Item */}
      <div className="col-lg-3 col-md-6">
        <div className="stats-item text-center w-100 h-100">
          <span data-purecounter-start={0} data-purecounter-end={32} data-purecounter-duration={1} className="purecounter" />
          <p>Workers</p>
        </div>
      </div>{/* End Stats Item */}
    </div>
  </div>
</section>




<section id="banner" className="call-to-action section">
  <div className="container" data-aos="fade-up" data-aos-delay={100}>
    <div className="row justify-content-center align-items-center position-relative">
      <div className="col-lg-8 mx-auto text-center">
        <h2 className="display-4 mb-4">Maecenas tempus tellus eget condimentum</h2>
        <p className="mb-4">
          Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel
        </p>
      </div>
      
      {/* Abstract Background Elements */}
      <div className="shape shape-1">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M47.1,-57.1C59.9,-45.6,68.5,-28.9,71.4,-10.9C74.2,7.1,71.3,26.3,61.5,41.1C51.7,55.9,35,66.2,16.9,69.2C-1.3,72.2,-21,67.8,-36.9,57.9C-52.8,48,-64.9,32.6,-69.1,15.1C-73.3,-2.4,-69.5,-22,-59.4,-37.1C-49.3,-52.2,-32.8,-62.9,-15.7,-64.9C1.5,-67,34.3,-68.5,47.1,-57.1Z" transform="translate(100 100)" />
        </svg>
      </div>
      <div className="shape shape-2">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M41.3,-49.1C54.4,-39.3,66.6,-27.2,71.1,-12.1C75.6,3,72.4,20.9,63.3,34.4C54.2,47.9,39.2,56.9,23.2,62.3C7.1,67.7,-10,69.4,-24.8,64.1C-39.7,58.8,-52.3,46.5,-60.1,31.5C-67.9,16.4,-70.9,-1.4,-66.3,-16.6C-61.8,-31.8,-49.7,-44.3,-36.3,-54C-22.9,-63.7,-8.2,-70.6,3.6,-75.1C15.4,-79.6,28.2,-58.9,41.3,-49.1Z" transform="translate(100 100)" />
        </svg>
      </div>

      {/* Dot Pattern Groups */}
      <div className="dots dots-1">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <pattern id="dot-pattern" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
            <circle cx={2} cy={2} r={2} fill="currentColor" />
          </pattern>
          <rect width={100} height={100} fill="url(#dot-pattern)" />
        </svg>
      </div>
    </div>
  </div>
</section>



    <section className="faq-9 faq section light-background" style={{backgroundColor: "whitesmoke"}} id="faq">
      <div className="container">
        <div className="row">
          <div className="col-lg-5" data-aos="fade-up">
            <h2 className="faq-title">Have a question? Check out the FAQ</h2>
            <p className="faq-description">Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero sit amet adipiscing sem neque sed ipsum.</p>
            <div class="faq-arrow d-none d-lg-block" data-aos="fade-up" data-aos-delay="200">
              <svg class="faq-arrow" width="200" height="211" viewBox="0 0 200 211" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M198.804 194.488C189.279 189.596 179.529 185.52 169.407 182.07L169.384 182.049C169.227 181.994 169.07 181.939 168.912 181.884C166.669 181.139 165.906 184.546 167.669 185.615C174.053 189.473 182.761 191.837 189.146 195.695C156.603 195.912 119.781 196.591 91.266 179.049C62.5221 161.368 48.1094 130.695 56.934 98.891C84.5539 98.7247 112.556 84.0176 129.508 62.667C136.396 53.9724 146.193 35.1448 129.773 30.2717C114.292 25.6624 93.7109 41.8875 83.1971 51.3147C70.1109 63.039 59.63 78.433 54.2039 95.0087C52.1221 94.9842 50.0776 94.8683 48.0703 94.6608C30.1803 92.8027 11.2197 83.6338 5.44902 65.1074C-1.88449 41.5699 14.4994 19.0183 27.9202 1.56641C28.6411 0.625793 27.2862 -0.561638 26.5419 0.358501C13.4588 16.4098 -0.221091 34.5242 0.896608 56.5659C1.8218 74.6941 14.221 87.9401 30.4121 94.2058C37.7076 97.0203 45.3454 98.5003 53.0334 98.8449C47.8679 117.532 49.2961 137.487 60.7729 155.283C87.7615 197.081 139.616 201.147 184.786 201.155L174.332 206.827C172.119 208.033 174.345 211.287 176.537 210.105C182.06 207.125 187.582 204.122 193.084 201.144C193.346 201.147 195.161 199.887 195.423 199.868C197.08 198.548 193.084 201.144 195.528 199.81C196.688 199.192 197.846 198.552 199.006 197.935C200.397 197.167 200.007 195.087 198.804 194.488ZM60.8213 88.0427C67.6894 72.648 78.8538 59.1566 92.1207 49.0388C98.8475 43.9065 106.334 39.2953 114.188 36.1439C117.295 34.8947 120.798 33.6609 124.168 33.635C134.365 33.5511 136.354 42.9911 132.638 51.031C120.47 77.4222 86.8639 93.9837 58.0983 94.9666C58.8971 92.6666 59.783 90.3603 60.8213 88.0427Z" fill="currentColor"></path>
              </svg>
            </div>
          </div>
          <div className="col-lg-7" data-aos="fade-up" data-aos-delay={300}>
            <div className="faq-container">
              {faqData.map((item, index) => (
                <div
                  className={`faq-item ${activeIndex === index ? "faq-active" : ""}`}
                  key={index}
                  onClick={() => toggleFAQ(index)}
                >
                  <h3>{item.question}</h3>
                  <div className="faq-content">
                    <p>{item.answer}</p>
                  </div>
                  <i className={`faq-toggle bi bi-chevron-right ${activeIndex === index ? "rotate" : ""}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="call-to-action" className="call-to-action section">
  <div className="container" data-aos="fade-up" data-aos-delay={100}>
    <div className="row justify-content-center align-items-center position-relative">
      <div className="col-lg-8 mx-auto text-center">
        <h2 className="display-4 mb-4">Maecenas tempus tellus eget condimentum</h2>
        <p className="mb-4">
          Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel
        </p>
      </div>
      
      {/* Abstract Background Elements */}
      <div className="shape shape-1">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M47.1,-57.1C59.9,-45.6,68.5,-28.9,71.4,-10.9C74.2,7.1,71.3,26.3,61.5,41.1C51.7,55.9,35,66.2,16.9,69.2C-1.3,72.2,-21,67.8,-36.9,57.9C-52.8,48,-64.9,32.6,-69.1,15.1C-73.3,-2.4,-69.5,-22,-59.4,-37.1C-49.3,-52.2,-32.8,-62.9,-15.7,-64.9C1.5,-67,34.3,-68.5,47.1,-57.1Z" transform="translate(100 100)" />
        </svg>
      </div>
      <div className="shape shape-2">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M41.3,-49.1C54.4,-39.3,66.6,-27.2,71.1,-12.1C75.6,3,72.4,20.9,63.3,34.4C54.2,47.9,39.2,56.9,23.2,62.3C7.1,67.7,-10,69.4,-24.8,64.1C-39.7,58.8,-52.3,46.5,-60.1,31.5C-67.9,16.4,-70.9,-1.4,-66.3,-16.6C-61.8,-31.8,-49.7,-44.3,-36.3,-54C-22.9,-63.7,-8.2,-70.6,3.6,-75.1C15.4,-79.6,28.2,-58.9,41.3,-49.1Z" transform="translate(100 100)" />
        </svg>
      </div>

      {/* Dot Pattern Groups */}
      <div className="dots dots-1">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <pattern id="dot-pattern" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
            <circle cx={2} cy={2} r={2} fill="currentColor" />
          </pattern>
          <rect width={100} height={100} fill="url(#dot-pattern)" />
        </svg>
      </div>
    </div>
  </div>
</section>

<section id="contact" className="contact section light-background">
  {/* Section Title */}
  <div className="container section-title" data-aos="fade-up">
    <h2>Connect with Us</h2>
    <p>Let's build something amazing together.</p>
  </div>{/* End Section Title */}
  <div className="container" data-aos="fade-up" data-aos-delay={100}>
    <div className="row g-4 g-lg-5">
      <div className="col-lg-5">
        <div className="info-box" data-aos="fade-up" data-aos-delay={200}>
          <h3>From Concept to Reality: Web Cloners' End-to-End Solutions
          </h3>
          <p>At Web Cloners, we turn visionary ideas into captivating reality. Our expert team crafts tailored web and mobile solutions, blending innovation, design and technical mastery. With precision and passion.</p>
          
        </div>
      </div>
      <div className="col-lg-7">
        <div className="contact-form" data-aos="fade-up" data-aos-delay={300}>
          <h3>We respect your privacy. Data confidentiality ensured.</h3>
          <form action="forms/contact.php" method="post" className="php-email-form" data-aos="fade-up" data-aos-delay={200}>
            <div className="row gy-4">
              <div className="col-md-6">
                <input type="text" name="name" className="form-control" placeholder="Your Full Name" required />
              </div>
              <div className="col-md-6 ">
                <input type="email" className="form-control" name="email" placeholder="Your Email Address" required />
              </div>
              <div className="col-md-12">
                <input type="number" className="form-control" name="number" placeholder="Your Contact Number" required />
              </div>
              <div className="col-12">
                <input type="text" className="form-control" name="subject" placeholder="Subject" required />
              </div>

              <div className="col-12">
                <textarea className="form-control" name="message" rows={6} placeholder="Message" required defaultValue={""} />
              </div>
              <div className="col-12 text-center">
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">Your message has been sent. Thank you!</div>
                <button type="submit" className="btn">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>





    </main>
    </div>
  );
}

export default About;
