import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

const testimonialsData = [
  {
    name: "Ridhi Tatineni",
    title: "CEO & Founder",
    image: "assets/img/testimonials/testimonials-1.jpg",
    text: "WebCloners are such great listeners, hardworking, and smart. We had our first project with them, and they absolutely met our needs with unlimited revisions. They completed it quickly and clearly knew what they were doing. I highly recommend everyone to work with them. I look forward to working on a full website with them in the near future! Thank you!!",
    author: "Ridhi Tatineni",
  },
  {
    name: "Russell Hene",
    title: "CEO & Founder",
    image: "assets/img/testimonials/testimonials-2.jpg",
    text: "WebCloners were very responsive and completed the work as agreed upon in a timely manner. They were extremely easy to work with and documented all of the work as requested. WebCloners were easy to communicate with and fulfilled the agreed-upon work as expected.",
    author: "Russell Hene",
  },
  {
    name: "William Grigoratos",
    title: "CEO & Founder",
    image: "assets/img/testimonials/testimonials-3.jpg",
    text: "WebCloners were fantastic to work with. I’d love to work with them again in the future. They are easy to talk to, understand technology, and grasp my requests very well.",
    author: "William Grigoratos",
  },
  {
    name: "Mike Noll",
    title: "CEO & Founder",
    image: "assets/img/testimonials/testimonials-4.jpg",
    text: "They provide updates daily and request meetings to clarify any doubts. When we encounter a problem in the development process or hit a blocker, they return with a list of solutions, which I absolutely respect. They dive deeply into the subject matter of the website and give valuable user experience feedback as well. They excel at feedback and offer perspectives to enhance the user experience. Working with WebCloners was fun and productive.",
    author: "Mike Noll",
  },
];

export default function Testimonials() {
  const [expandedStates, setExpandedStates] = useState(
    Array(testimonialsData.length).fill(false)
  );

  useEffect(() => {
    AOS.init({
      duration: 2000, // Animation duration (2 seconds for slower animation)
      once: true, // Ensure animation occurs only once
      offset: '80%', // Trigger animation when 80% of the section is in view
    });
  }, []);

  const toggleExpand = (index) => {
    setExpandedStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };

  return (
    <section id="testimonials" style={{backgroundColor: '#F9F9F9'}} className="testimonials section light-background">
      <div className="container section-title" data-aos="fade-up">
        <h2 className="client-r">Testimonials</h2>
        <p>We don't like to blow our own trumpet, how about we let our clients do that for us.</p>
      </div>
      <div className="container">
        <div className="row g-5">
          {testimonialsData.map((testimonial, index) => (
            <TestimonialItem
              key={index}
              index={index}
              isExpanded={expandedStates[index]}
              toggleExpand={toggleExpand}
              {...testimonial}
              animationDirection={getAnimationDirection(index)} // Pass animation direction
            />
          ))}
        </div>
      </div>
    </section>
  );
}

// Function to dynamically decide animation direction based on index
const getAnimationDirection = (index) => {
  switch (index) {
    case 0: return 'fade-left';
    case 1: return 'fade-up';
    case 2: return 'fade-down';
    case 3: return 'fade-right';
    default: return 'fade-up';
  }
};

function TestimonialItem({ index, name, title, image, text, author, isExpanded, toggleExpand, animationDirection }) {
  return (
    <div className="col-lg-6" data-aos={animationDirection} data-aos-delay={100 * index}>
      <div className="testimonial-item">
        <img src={image} className="testimonial-img" alt={name} />
        <h3>{name}</h3>
        <h4>{title}</h4>
        <div className="stars">
          <i className="bi bi-star-fill" />
          <i className="bi bi-star-fill" />
          <i className="bi bi-star-fill" />
          <i className="bi bi-star-fill" />
          <i className="bi bi-star-fill" />
        </div>
        <p className={`testimonial-text ${isExpanded ? 'expanded' : ''}`}>
          <i className="bi bi-quote quote-icon-left" />
          <span>
            {isExpanded ? text : `${text.slice(0, 150)}... `}
            <span
              className="text-primary"
              onClick={() => toggleExpand(index)}
              style={{ cursor: 'pointer' }}
            >
              {isExpanded ? ' Show Less' : 'Read More'}
            </span>
          </span>
          <i className="bi bi-quote quote-icon-right" />
          <br />
          <span style={{ float: 'inline-end', color: '#00509E' }}>~{author}</span>
        </p>
      </div>
    </div>
  );
}
