import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      <footer id="footer" className="footer">
        <div className="container footer-top">
          <div className="row gy-4" id='footer-text'>
            <div className="col-lg-4 col-md-6 footer-about">
              <Link to="/" className="logo d-flex align-items-center">
                <img src="\assets\img\Asset 2@3x.png" style={{ width: "50%" }} alt="Logo" />
              </Link>
              <div className="footer-contact pt-3">
                <p>USA’s leading web design and development firm offering all-inclusive, full-stack web design and development services to assist you to go digital and outshine your competitors with ease.<br />
                Phone: <a style={{color: "#202020"}} href="tel:+17373590066">+1 (737) 359 0066</a></p>
              </div>
              <div className="social-links d-flex mt-4">
                <Link to="#"><i className="bi bi-twitter" /></Link>
                <Link to="#"><i className="bi bi-facebook" /></Link>
                <Link to="#"><i className="bi bi-instagram" /></Link>
                <Link to="#"><i className="bi bi-linkedin" /></Link>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><Link to="/"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> Home</Link></li>
                <li><Link to="/about"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> About us</Link></li>
                <li><Link to="/services"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> Services</Link></li>
                <li><Link to="/portfolio"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> Portfolio</Link></li>
                <li><Link to="/services"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> Terms of service</Link></li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li><Link to="/services"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> Web Design</Link></li>
                <li><Link to="/services"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> Web Development</Link></li>
                <li><Link to="/services"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> Product Management</Link></li>
                <li><Link to="/services"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> Marketing</Link></li>
                <li><Link to="/services"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> Graphic Design</Link></li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Contacts</h4>
              <ul>
                <li><a href="#"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> 447 Broadway, New York, NY 10013 US</a></li>
                <li><a href="tel:+17373590066"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> <strong>Phone:</strong> +1 (737) 359 0066</a></li>
                <li><a href="mailto:contact@webcloners.com"><i className="bi bi-chevron-left" style={{ fontWeight: 'bold' }} /> <strong>Email:</strong> contact@webcloners.com</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container copyright text-center mt-4">
          <p>© <span>Copyright</span> <strong className="px-1 sitename">WebCloners</strong> <span>All Rights Reserved</span></p>
        </div>
      </footer>
    </div>
  )
}

export default Footer;
