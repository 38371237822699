import React, { useState } from "react";
import Swal from 'sweetalert2';


const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubscribe = () => {
    // Clear previous error
    setError("");

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Enter a valid email address.");
      return;
    }

    // Allow only non-Gmail addresses (if requested)
    // if (email.toLowerCase().includes("@gmail.com")) {
    //   setError("Gmail addresses are not allowed.");
    //   return;
    // }

    // Proceed with subscription
    setError('');
    setEmail('')
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Successfully Subscribed!',
      text: 'Thank you for subscribing to our newsletter!',
      showConfirmButton: false,
      timer: 4000,
      toast: true,
    });

  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubscribe();
    }
  };

  return (
    <section id="call-to-action" className="call-to-action section">
      <div className="container" data-aos="fade-up" data-aos-delay={100}>
        <div className="row justify-content-center align-items-center position-relative">
          <div className="col-lg-8 mx-auto text-center">
            <h2 className="display-4 mb-4">Subscribe to our newsletter</h2>
            <p className="mb-4">
              Signup for our newsletter to stay up to date on sales and events.
            </p>
          </div>
          <div className="col-md-6">
            {error && (
              <p style={{ fontWeight: "600", marginBottom: "5px" }}>
                {error}
              </p>
            )}
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown} // Trigger on Enter key press
              required
            />
            <button
              className="subscribe-btn"
              type="button"
              onClick={handleSubscribe}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
