import React, { useState } from 'react';
import ContactSection from './Contact';

const Pricing = () => {
  const [selectedCard, setSelectedCard] = useState(null); // Selected card index
  const [selectedPackage, setSelectedPackage] = useState(null); // Store selected package info

  const handleCardClick = (index) => {
    setSelectedCard(selectedCard === index ? null : index); // Toggle description visibility
  };

  const handleBuyNowClick = (packageData) => {
    setSelectedPackage(packageData); // Store selected package information when "Sign Up" is clicked
  };

  const pricingData = [
    {
      title: 'Tier 1 Package',
      price: '$300',
      detailedDescription: [
        'Dedicated Project Manager: Oversees the project and ensures smooth delivery.',
        'Website Structure & Design: Includes 4 custom pages.',
        'Domain & Hosting: Domain for 1 year; 3 months of free hosting.',
        'Content & SEO: SEO-optimized homepage content.',
        'E-commerce Setup: Initial setup for smooth product organization.',
        'Revisions & Support: Unlimited revisions during development.',
      ],
      features: [
        '4-page website + shop pages',
        '$10 domain registration',
        'E-commerce website on WordPress/Shopify (theme-based)',
        'SEO-optimized content for homepage',
        '3 months of free shared hosting',
        'Revisions included',
        'Dedicated Project Manager',
      ],
    },
    {
      title: 'Tier 2 Package',
      price: '$1,000',
      detailedDescription: [
        'Dedicated Project Manager: Main point of contact, ensuring timely updates.',
        'Website Structure & Design: Custom WordPress/Shopify, up to 10 pages.',
        'Domain & Hosting: 1-year domain; 6 months premium hosting.',
        'Content & SEO: SEO content for 5 pages; blog setup.',
        'E-commerce Setup: Setup for 30 products with advanced filtering.',
        'Post-Launch Support: 1 month of bug fixes; 6 months support.',
      ],
      features: [
        'Custom WordPress/Shopify design',
        'Up to 10 pages',
        '1-year domain, 6 months premium hosting',
        'SEO for 5 pages + blog setup',
        '30-product setup with advanced filtering',
        '1 month of bug-fix support',
        '6 months of customer support',
        'Dedicated Project Manager',
      ],
    },
    {
      title: 'Tier 3 Package',
      price: '$2,500',
      detailedDescription: [
        'Dedicated Project Manager: Oversees all aspects of the project and ensures timely delivery.',
        'Website Structure & Design: Up to 20 custom pages with animations.',
        'Domain & Hosting: 1-year domain and high-speed VPS hosting.',
        'Content & SEO: SEO-optimized content for 10 pages; advanced blog setup.',
        'E-commerce Setup: Setup for 100 products with advanced integrations.',
        'Post-Launch Support: 1 month of bug fixes; 1 year of extended support.',
      ],
      features: [
        'Fully custom WordPress/Shopify design',
        'Up to 20 pages with animations',
        '1-year domain + VPS hosting',
        'SEO for 10 pages + blog setup with 3 posts',
        '100-product setup + custom features',
        '1 month of bug-fix support',
        '1-year extended support',
        'Dedicated Project Manager',
      ],
    },
  ];

  return (
    <div>
      <section id="pricing" className="pricing section light-background">
        <div className="container section-title" data-aos="fade-up">
          <h2>Pricing</h2>
          <p>Choose the package that best suits your needs and goals.</p>
        </div>

        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row g-4 justify-content-center">
            {pricingData.map((plan, index) => (
              <div
                key={index}
                className={`col-lg-4`}
                data-aos="fade-up"
                data-aos-delay={100 * (index + 1)}
              >
                <div
                  className={`pricing-card ${index === 1 ? 'popular-card' : ''}`}
                  onClick={() => handleCardClick(index)}
                >
                  {index === 1 && <div className="popular-badge">Most Popular</div>}
                  <h3>{plan.title}</h3>
                  <div className="price">
                    <span className="currency"></span>
                    <span className="amount">{plan.price}</span>
                  </div>

                  {selectedCard === index && (
                    <div className="features-list">
                      <h4>Description:</h4>
                      <ul>
                        {plan.detailedDescription.map((item, i) => (
                          <li key={i} style={{ marginBottom: '10px' }}>
                            <i className="bi bi-check-circle-fill"></i> {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {selectedCard !== index && (
                    <>
                      <h4>Features Included:</h4>
                      <ul className="features-list">
                        {plan.features.map((feature, i) => (
                          <li key={i} style={{ marginBottom: '10px' }}>
                            <i className="bi bi-check-circle-fill"></i> {feature}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  <a
                    href="#contact"
                    className="btn btn-price btn-primary"
                    onClick={() => handleBuyNowClick(plan)} // Store selected package info
                  >
                    Sign Up
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <ContactSection selectedPackage={selectedPackage} />
    </div>
  );
};

export default Pricing;
