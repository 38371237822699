import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import Swal from 'sweetalert2';
// import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

const Popup = ({ onClose }) => {
  const location = useLocation(); // Get current route location
  const [step, setStep] = useState(1);
  const [selection, setSelection] = useState(null);
  const [priceRange, setPriceRange] = useState(null);
  const [contactMethod, setContactMethod] = useState(null);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '' });
  const [showPopup, setShowPopup] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const isPopupShown = sessionStorage.getItem('popupShown'); // Check if popup has been shown
    const isFirstVisitToHome = location.pathname === '/' && !isPopupShown;

    if (isFirstVisitToHome) {
      const timer = setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem('popupShown', 'true'); // Set flag in session storage
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (showPopup) {
      setStep(1);
    }
  }, [showPopup]);

  // Handle selection of options
  const handleSelection = (type) => {
    setSelection(type);
    setStep(2);
  };

  const handlePriceRange = (range) => {
    setPriceRange(range);
    setStep(3);
  };

  const handleContactMethod = (method) => {
    setContactMethod(method);
    setStep(4);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required.';
    if (!formData.email.match(/^\S+@\S+\.\S+$/)) newErrors.email = 'Invalid email format.';
    if (!formData.phone) newErrors.phone = 'Phone number is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Form Submitted Successfully!',
        text: 'Thank you for providing your details!',
        showConfirmButton: false,
        timer: 4000,
        toast: true,
      });

      setShowPopup(false);
      onClose();
    }
  };

  return (
    showPopup && (
      <div className="popup-overlay">
        <div className="popup-content">
          <button className="close-button" onClick={() => { setShowPopup(false); onClose(); }}>X</button>

          {step === 1 && (
            <>
              <h2 style={{ fontSize: '24px' }} className="popup-title">
                Giving your vision a smooth <span className="highlighted-text">Transition To Reality</span>
              </h2>
              <p className="description-text">
                By enabling an innovative digital experience that fulfills new-age demands.
              </p>
              <div className="options-row">
                <div className="option-card" onClick={() => handleSelection('Mobile Application')}>
                  <i className="bi bi-apple icon"></i> <i className="bi bi-android2 icon"></i>
                  <p>I Need A Mobile Application</p>
                </div>
                <div className="option-card" onClick={() => handleSelection('Web Application')}>
                  <i className="bi bi-laptop icon"></i>
                  <p>I Need A Web Application</p>
                </div>
                <div className="option-card highlighted-option" onClick={() => handleSelection('Custom Website')}>
                  <i className="bi bi-code-slash icon"></i>
                  <p>I Need A Custom Website</p>
                </div>
              </div>
              <div className="progress-bar" style={{ height: '6px' }}>
                <div className="progress-fill" style={{ width: '25%' }}></div>
              </div>
              <button className="back-button" disabled style={{ opacity: 0.5 }}>Back</button>
            </>
          )}

          {step === 2 && (
            <>
              <h2 className="popup-title">
                Giving your vision a smooth <span>Transition To Reality</span>
              </h2>
              <p className='description-text'>What's your budget for this project?</p>
              <h2 className='price-popup-h2'>Approximate Budget</h2>
              <div className="price-options">
                <div className="price-card" onClick={() => handlePriceRange('$500 - $1000')}>$500 - $1000</div>
                <div className="price-card" onClick={() => handlePriceRange('$1000 - $5000')}>$1000 - $5000</div>
                <div className="price-card" onClick={() => handlePriceRange('$5000+')}>$5000+</div>
              </div>
              <div className="progress-bar" style={{ height: '6px' }}>
                <div className="progress-fill" style={{ width: '50%' }}></div>
              </div>
              <button onClick={() => setStep(1)} className="back-button">Back</button>
            </>
          )}

          {step === 3 && (
            <>
              <h2 className="popup-title">
                Giving your vision a smooth <span>Transition To Reality</span>
              </h2>
              <p>What's the best way for us to reach you to discuss this project?</p>
              <div className="contact-options">
                <div className="contact-card" onClick={() => handleContactMethod('Call')}>
                  <i className="bi bi-telephone-fill"></i>
                  <p>Please Call Me</p>
                </div>
                <div className="contact-card" onClick={() => handleContactMethod('Email')}>
                  <i className="bi bi-envelope-fill"></i>
                  <p>Please Email Me</p>
                </div>
              </div>
              <div className="progress-bar" style={{ height: '6px' }}>
                <div className="progress-fill" style={{ width: '75%' }}></div>
              </div>
              <button onClick={() => setStep(2)} className="back-button">Back</button>
            </>
          )}

          {step === 4 && (
            <>
              <h2>Enter Your Details</h2>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {errors.name && <span className="error">{errors.name}</span>}
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <span className="error">{errors.email}</span>}
                <div className="phone-input-wrapper">
                  <PhoneInput
                    country="us"
                    value={formData.phone}
                    onChange={(value) => setFormData((prevData) => ({ ...prevData, phone: value }))}
                    inputProps={{
                      name: 'phone',
                      required: true,
                    }}
                    specialLabel=""
                  />
                </div>
                {errors.phone && <span className="error">{errors.phone}</span>}
                <button type="submit">Submit</button>
              </form>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default Popup;
