import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import GLightbox from 'glightbox'; // Ensure correct import
import 'glightbox/dist/css/glightbox.css'; // Import GLightbox CSS
import Client from '../Client';

const portfolioItems = [
  { id: 5, title: 'Website', category: 'Website', video: 'assets/img/masonry-portfolio/web-video-6.mp4', link: 'https://www.asamakarealestate.com/' },
  { id: 12, title: 'Dashboard', category: 'Dashboard', image: 'assets/img/masonry-portfolio/Dashboard-3.png', link: 'https://xd.adobe.com/view/001ce2eb-83b7-4dbc-93ac-ac550cde77f7-554f/' },
  { id: 6, title: 'Website', category: 'Website', video: 'assets/img/masonry-portfolio/web-video-5.mp4', link: 'https://www.susannekaufmann.com/' },
  { id: 11, title: 'Dashboard', category: 'Dashboard', image: 'assets/img/masonry-portfolio/Dashboard-2.png', link: 'https://xd.adobe.com/view/43893da4-ca7a-48d4-9a16-e2968d8483c6-16da/?fullscreen&hints=off' },
  { id: 3, title: 'Website', category: 'Website', video: 'assets/img/masonry-portfolio/web-video-3.mp4', link: 'https://bonusbank.com.br/' },
  { id: 9, title: 'Website', category: 'Website', video: 'assets/img/masonry-portfolio/web-video-9.mp4', link: 'https://www.unrvld.com/' },
  { id: 10, title: 'App', category: 'App', video: 'assets/img/masonry-portfolio/app-video-1.mp4', link: 'https://play.google.com/store/apps/details?id=ee.mtakso.client' },
  { id: 7, title: 'Website', category: 'Website', video: 'assets/img/masonry-portfolio/web-video-7.mp4', link: 'https://cowboy.com/' },
  { id: 1, title: 'Website', category: 'Website', video: 'assets/img/masonry-portfolio/web-video-2.mp4', link: 'https://www.reform.digital/' },
  { id: 13, title: 'Dashboard', category: 'Dashboard', video: 'assets/img/masonry-portfolio/Dashboard-1.mp4', link: 'https://xd.adobe.com/view/65186ac7-b343-4fb7-9183-5074c11a7f09-304b/?hints=off' },
  { id: 14, title: 'WebApp', category: 'WebApp', video: 'assets/img/masonry-portfolio/WebApp-1.mp4', link: 'https://meatverse-website.vercel.app/' },
  { id: 2, title: 'Website', category: 'Website', video: 'assets/img/masonry-portfolio/web-video-1.mp4', link: 'https://connectory.webflow.io/' },
  { id: 4, title: 'Website', category: 'Website', video: 'assets/img/masonry-portfolio/web-video-4.mp4', link: 'https://ravecoffee.co.uk/' },
  { id: 20, title: 'Dashboard', category: 'Dashboard', image: 'assets/img/masonry-portfolio/Dashboard-4.png', link: 'https://xd.adobe.com/view/a7d5b5f4-cd39-4c29-a400-62b2db3d6211-f286/?fullscreen&hints=off' },
  { id: 8, title: 'Website', category: 'Website', video: 'assets/img/masonry-portfolio/web-video-8.mp4', link: 'https://birchandbind.com/' },
];


const Portfolio = () => {
  useEffect(() => {
    GLightbox({
      selector: '.glightbox',
      loop: true,
      zoomable: true,
      draggable: true,
    });
  }, []);

  const [activeFilter, setActiveFilter] = useState('all');
  const [visibleItemsCount, setVisibleItemsCount] = useState(9); // Show 8 items initially
  const [isLoading, setIsLoading] = useState(false); // Track loading state

  const filteredItems =
    activeFilter === 'all'
      ? portfolioItems
      : portfolioItems.filter(item => item.category === activeFilter);

  const visibleItems = filteredItems.slice(0, visibleItemsCount);

  const handleShowMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setVisibleItemsCount(prevCount => prevCount + 8); // Show 8 more items
      setIsLoading(false);
    }, 1000); // Simulate loading time
  };
  return (
    <div>
 <section id="hero" className="hero section">
  <div className="container" data-aos="fade-up" data-aos-delay="100">
    <div className="row">
      <div className="col-lg-6">
        <div className="hero-content" data-aos="fade-up" data-aos-delay="200">
        <Link to="/portfolio">
          <div className="company-badge mb-4">
             
            <i className="bi bi-gear-fill me-2"></i>
            Working for your digital fitness
           
          </div>
          </Link>
          <h1 className="mb-4">
           Our
           Portfolio
          </h1>
          <p className="mb-4 mb-md-5" id='para'>
          Welcome to Web Cloners, your fitness coaches for digital success! We're a team of tech athletes passionate about empowering businesses to reach new heights
          </p>
          <div className="hero-buttons">
            <Link to="/services" className="btn btn-primary me-0 me-sm-2 mx-1">Warm up for success</Link>
          </div>
        </div>
      </div>
      
      <div className="col-lg-6">
      <div className="hero-image">
          <img src="https://www.thebinarydesign.com/images/s-1-2.png" alt="Mobile" className="device mobile" />
          <img src="https://www.thebinarydesign.com/images/s-1-3.png" alt="Tablet" className="device tablet-bottom" />
          <img src="https://www.thebinarydesign.com/images/s-1-4.png" alt="Tablet" className="device tablet" />
          <img src="https://www.thebinarydesign.com/images/s-1-1.png" alt="Laptop" className="device laptop" />
      
        </div>
      </div>
    </div>
  </div>
</section>

<Client />

 {/* Portfolio Section */}
 <section id="portfolio" className="portfolio section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Our Portfolio</h2>
          <p>
            <span>Check Our&nbsp;</span>
            <span className="description-title">Portfolio</span>
          </p>
        </div>

        <div className="container">
          {/* Filters */}
          <div className="d-flex justify-content-center" style={{ paddingBottom: '50px' }}>
            <ul className="portfolio-filters isotope-filters" data-aos="fade-up" data-aos-delay={100}>
              <li onClick={() => setActiveFilter('all')} className={activeFilter === 'all' ? 'filter-active' : ''}>All</li>
              <li onClick={() => setActiveFilter('Website')} className={activeFilter === 'Website' ? 'filter-active' : ''}>Website</li>
              <li onClick={() => setActiveFilter('App')} className={activeFilter === 'App' ? 'filter-active' : ''}>App</li>
              <li onClick={() => setActiveFilter('Dashboard')} className={activeFilter === 'Dashboard' ? 'filter-active' : ''}>Dashboard</li>
              <li onClick={() => setActiveFilter('WebApp')} className={activeFilter === 'WebApp' ? 'filter-active' : ''}>Web App</li>
            </ul>
          </div>

          {/* Portfolio Items */}
          <div className="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay={200}>
            {visibleItems.map(item => (
              <div key={item.id} className={`col-lg-4 col-md-6 portfolio-item isotope-item filter-${item.category}`}>
                <a href={item.link} target="_blank" rel="noopener noreferrer" className="glightbox">
                  {item.video ? (
                    <video
                      className="img-fluid custom-video lazy"
                      src={item.video}
                      muted
                      loop
                      autoPlay
                      preload="metadata"
                    />
                  ) : (
                    <img src={item.image} className="img-fluid lazy" alt={item.title} />
                  )}
                </a>
              </div>
            ))}
          </div>

          {/* Show More Button */}
          {!isLoading && visibleItems.length < filteredItems.length && (
            <div className="text-center mt-4">
              <button className="btn btn-primary subscribe-btn" onClick={handleShowMore}>
                Show More
              </button>
            </div>
          )}

          {/* Loading Spinner */}
          {isLoading && (
            <div className="text-center mt-4">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </section>
      <section id="contact" className="contact section light-background">
  {/* Section Title */}
  <div className="container section-title" data-aos="fade-up">
    <h2>Connect with Us</h2>
    <p>Let's build something amazing together.</p>
  </div>{/* End Section Title */}
  <div className="container" data-aos="fade-up" data-aos-delay={100}>
    <div className="row g-4 g-lg-5">
      <div className="col-lg-5">
        <div className="info-box" data-aos="fade-up" data-aos-delay={200}>
          <h3>From Concept to Reality: Web Cloners' End-to-End Solutions
          </h3>
          <p>At Web Cloners, we turn visionary ideas into captivating reality. Our expert team crafts tailored web and mobile solutions, blending innovation, design and technical mastery. With precision and passion.</p>
          
        </div>
      </div>
      <div className="col-lg-7">
        <div className="contact-form" data-aos="fade-up" data-aos-delay={300}>
          <h3>We respect your privacy. Data confidentiality ensured.</h3>
          <form action="forms/contact.php" method="post" className="php-email-form" data-aos="fade-up" data-aos-delay={200}>
            <div className="row gy-4">
              <div className="col-md-6">
                <input type="text" name="name" className="form-control" placeholder="Your Full Name" required />
              </div>
              <div className="col-md-6 ">
                <input type="email" className="form-control" name="email" placeholder="Your Email Address" required />
              </div>
              <div className="col-md-12">
                <input type="number" className="form-control" name="number" placeholder="Your Contact Number" required />
              </div>
              <div className="col-12">
                <input type="text" className="form-control" name="subject" placeholder="Subject" required />
              </div>

              <div className="col-12">
                <textarea className="form-control" name="message" rows={6} placeholder="Message" required defaultValue={""} />
              </div>
              <div className="col-12 text-center">
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">Your message has been sent. Thank you!</div>
                <button type="submit" className="btn">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  );
};

export default Portfolio;
