import React, { useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { NavLink } from 'react-router-dom';

const Header = () => {
  useEffect(() => {
    const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');
    const liveChatLink = document.getElementById('liveChatLink');
    const dropdownToggleLinks = document.querySelectorAll('.toggle-dropdown');

    const mobileNavToggle = () => {
      document.body.classList.toggle('mobile-nav-active');
      mobileNavToggleBtn.classList.toggle('bi-list');
      mobileNavToggleBtn.classList.toggle('bi-x');
    };

    const openChatPopup = () => {
      const chatPopup = document.getElementById("chatPopup");
      chatPopup.style.display = chatPopup.style.display === "none" || chatPopup.style.display === "" ? "block" : "none";
    };

    const toggleDropdown = (e) => {
      e.preventDefault();
      const parentLi = e.target.closest('li');
      parentLi.classList.toggle('dropdown-active');
    };

    if (mobileNavToggleBtn) {
      mobileNavToggleBtn.addEventListener('click', mobileNavToggle);
    }

    if (liveChatLink) {
      liveChatLink.addEventListener('click', (e) => {
        e.preventDefault();
        openChatPopup();
      });
    }

    dropdownToggleLinks.forEach(toggleLink => {
      toggleLink.addEventListener('click', toggleDropdown);
    });

    // Cleanup event listeners on component unmount
    return () => {
      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.removeEventListener('click', mobileNavToggle);
      }
      if (liveChatLink) {
        liveChatLink.removeEventListener('click', openChatPopup);
      }
      dropdownToggleLinks.forEach(toggleLink => {
        toggleLink.removeEventListener('click', toggleDropdown);
      });
    };
  }, []);

  return (
    <div>
   {/* Top Bar */}
<div className="top-bar d-flex justify-content-between align-items-center">
  <div className="contact-info">
    <a style={{ color: "#fff" }} href="mailto:contact@webcloners.com">
      <span>contact@webcloners.com</span>
    </a>
    <span className="pipe"> | </span>
    <a style={{ color: "#fff" }} href="tel:+17373590066">
      <span> +1 (737) 359 0066</span>
    </a>
  </div>
  <div className="additional-links">
    <a href="#live-chat" id="liveChatLink">Live Chat</a>
    <span className="pipe"> | </span>
    <a href="#login">Login</a>
  </div>
</div>


      {/* Main Header */}
      <header id="header" className="header d-flex align-items-center">
        <div className="header-container container-fluid position-relative d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center me-auto me-xl-0">
            <img src="/assets/img/Asset 2@3x.png" alt="Logo" />
          </a>
          <nav id="navmenu" className="navmenu">
            <ul>
              <li><NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Home</NavLink></li>
              <li><NavLink to="/about#hero" className={({ isActive }) => (isActive ? 'active' : '')}>About Us</NavLink></li>
              <li><NavLink to="/portfolio" className={({ isActive }) => (isActive ? 'active' : '')}>Portfolio</NavLink></li>
              <li><NavLink to="/services" className={({ isActive }) => (isActive ? 'active' : '')}>Services</NavLink></li>
              <li className="dropdown">
                <a href="#" className="toggle-dropdown"><span>More</span> <i className="bi bi-chevron-down" /></a>
                <ul>
                  <li><a href="#">Team</a></li>
                  <li><a href="#">Faqs</a></li>
                  <li><a href="#">Blogs</a></li>
                </ul>
              </li>
              {/* <li><a href="#contact">Contact</a></li> */}
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list" />
          </nav>
          <NavLink className="btn-getstarted" to="/about#contact">Contact Us</NavLink>
        </div>
      </header>
    </div>
  );
};

export default Header;
